import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import HeaderImage from "../../static/media/tech-check.png"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"
import ContactButton from '../components/contact-button/contact-button'
import { Helmet } from "react-helmet"

const items = [
  { text: "Vanaf €350 + BTW", key: 1, icon: "fas fa-money-bill-wave-alt" },
  { text: "Eigendommen tot 200m² (€1 + BTW per extra m²)", key: 2, icon: "fas fa-ruler-combined" },
  {
    text: "Vervoer 50km inbegrepen (€0,30 + BTW per extra km)",
    key: 3,
    icon: "fas fa-car",
  },
]

export default () => (
  <Layout>
    <HeaderSmall title="Tech-check" imageUrl={HeaderImage}></HeaderSmall>
    <DefaultLayout>
      <Helmet>
        <title>Tech-check vastgoed aankoopbegeleding</title>
        <meta name="description" content="Wij onderzoeken een pand/ huis op alle technische aspecten zodat jij weet wat je aankoopt, of wat je beter niet aankoopt."></meta>
        <meta name="keywords" context="techcheck, tech-check, vastgoed, aakoopbegeleiding, huis, pand, immo, immophone, gent, De Pinte"></meta>          
      </Helmet>
      <div className="details">
        <DefaultTextBlock
          title="Tech-check"
          text="Complete technische doorlichting van de eigendom nl de staat van de gebouwschil, muren, stabiliteit, dak, gevaarlijke materialen, vocht, sanitaire installaties en afvoeren, elektrische installatie, energetische prestatie..."
        ></DefaultTextBlock>
        <FeatureList items={items} title="Concreet"></FeatureList>
      </div>
      <ContactButton></ContactButton>
    </DefaultLayout>
  </Layout>
)
